<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder">
                    <label>Employee Phone Number </label>
                    <the-mask v-if="this.userPhone" type="text" @focus="ResetDirty('Phone')" @blur="SetDirty('Phone')"
                        :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '1-###-###-####']" style="margin-top:1px;"
                        class="form-control" autocomplete="off" :maxlength="14" v-model="ProdExpVendor.Phone"
                        placeholder="Employee Phone Number" disabled></the-mask>

                    <b-form-input v-else placeholder="N/A" disabled></b-form-input>

                    <div class="error-message-format"
                        v-if="$v.ProdExpVendor.Phone.$dirty && !$v.ProdExpVendor.Phone.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder" style="margin-top: -22px;">
                    <label>ENTITY </label>
                    <OmniSelect class="" placeholder="Select Entity" name="Entity"
                    :showLabel="false" label="value" v-model="ProdExpVendor.Entity" :options="EntityList"></OmniSelect>
                </div>
                <div class="fieldborder" style="margin-top: -22px;">
                    <label>AGENCY </label>
                    <OmniSelect placeholder="Select Agency" name="Media" :showLabel="false" label="Select Media"
                        :options="AgencyList" v-model="ProdExpVendor.EmployeeAgency" />
                </div>

                <div class="fieldborder" style="margin-top: -22px;">
                    <b-form-group label="" class="timeonly-text">
                        <label>REQUEST TYPE </label>
                        <b-form-radio-group style="margin-left:10px;" class="omniipclass" id="time-only"
                            v-model="ProdExpVendor.RequestType" name="time-only">
                            <b-form-radio value="production">PRODUCTION</b-form-radio>
                            <b-form-radio value="expense">EXPENSE</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <br>
                <br>
                <br>
                <br>
            </div>
        </div>

        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid">
                NEXT
            </button>
        </div>
    </div>
</template>

<style scoped>
.error-message {
    padding-top: 10px;
    padding-bottom: 5px;
    height: 14px !important;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
}

.floating-label-input {
    margin-top: 0px !important;
}

.fieldborder {
    padding-bottom: 30px !important;
}

.omniipclass {
    /* width: 415px !important; */
    height: 35px !important;
    border: #e9eef0 !important;
    /* style="width:415px; height:35px;" */
}

.Text {
    width: 250px;
    height: 196px;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.Text .text-style-1 {
    font-weight: 600;
}
</style>

<script>
import { mapGetters } from "vuex";
import service from "../../../services/Dropdowns";
import { required, minLength, requiredIf, maxLength, numeric } from "vuelidate/lib/validators";
import { TheMask } from 'vue-the-mask'
export default {
    data() {
        return {
            EntityList:[],
            userPhone: null,
            AgencyList: [],
            validationtextbox: false,
            reachUserData:null,
            EditLoad: {
                EntityLoaded: false,
                AgencyLoaded: false,
            },
        }
    },
    components: {
        TheMask
    },
    validations: {
        ProdExpVendor: {
            Phone: {
                required: requiredIf(function () {
                    return this.userPhone;
                }),
                minLength: minLength(10),
                maxLength: maxLength(10),
                numeric
            },
            Entity: {
                required
            },
            EmployeeAgency: {
                required
            },
            RequestType: {
                required
            }
        }
    },
    async created() {
        await this.getPhoneNumber();
        if (!(this.CanCreatProductionExpenseNewMediaRequest || this.CanCreateVendorRequest)) {
            this.$router.push({
                name: "error",
                params: { message: "UnAuthorized" },
            });
        }

    },
    mounted() {
        this.LoadDropdownValues();
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    watch: {
        "ProdExpVendor.EmployeeAgency": function (val) {
            let Agency = this.AgencyList.findIndex((item) => {
                return item.value == val;
            });
            if (Agency > -1) {
                this.ProdExpVendor.AgencyName = this.AgencyList[Agency].label;
            } else {
                this.ProdExpVendor.AgencyName = "";
            }
        },
        "ProdExpVendor.Entity": function (val) {
            let entity = this.EntityList.findIndex((item) => {
                return item.value == val;
            });
            if (entity > -1) {
                this.ProdExpVendor.EntityName = this.EntityList[entity].label;
            } else {
                this.ProdExpVendor.EntityName = "";
            }
            this.EntityChange();
        },
    },
    computed: {
        ...mapGetters('prodexpvendor', {
            ProdExpVendor: 'ProdExpVendor',
        }),
        IsAgencyLoaded() {
            return this.EditLoad && this.EditLoad.AgencyLoaded;
        },
        IsEntityLoaded() {
            return this.EditLoad && this.EditLoad.EntityLoaded;
        },
        CanCreatProductionExpenseNewMediaRequest() {
            return this.reachUserData && this.reachUserData.MatrixEmailID && this.reachUserData.MatrixEmailID.trim() != "";
        },
        CanCreateVendorRequest() {
            return this.$store.getters.canCreateVendorRequest;
        },
        IsEdit() {
            return this.id && parseInt(this.id) > 0;
        }
    },
    methods: {
        AssignAgencyName() {
            let agency = this.AgencyList.findIndex((item) => {
                return item.value == this.ProdExpVendor.EmployeeAgency;
            });
            if (agency > -1) {
                this.ProdExpVendor.AgencyName = this.AgencyList[agency].label;
            } else {
                this.ProdExpVendor.AgencyName = "";
            }
        },
        async LoadAgencySelectedValue() {
            if (this.IsEdit) {
                this.$set(this.EditLoad, "AgencyLoaded", true);
                await this.AssignAgencyName();
            }
        },
        LoadEntitySelectedValue() {
            if (this.IsEdit) {
                this.$set(this.ProdExpVendor, "Entity", this.ProdExpVendor.Entity);
                this.$set(this.EditLoad, "EntityLoaded", true);
            }
        },
        EntityChange() {
            var entityid = this.ProdExpVendor.Entity;
            service
                .getAgencyDropdownValues(entityid, entityid)
                .then((res) => {
                this.AgencyList = res.data;
                if (this.IsEdit && !this.IsAgencyLoaded) {
                    this.LoadAgencySelectedValue();
                } else {
                    if (res.data.length == 1) {
                        this.ProdExpVendor.EmployeeAgency = res.data[0].value;
                    } else {
                        this.ProdExpVendor.EmployeeAgency = null;
                    }
                }
                })
                .catch((err) => {
                    alert(err);
                });
            },
        async LoadDropdownValues() {
            service
            .getOriginalEntityRecords("Financial Entity")
            .then((res) => {
                this.EntityList = res.data;
                if (this.IsEdit && !this.IsEntityLoaded) {
                    this.LoadEntitySelectedValue();
                }
            })
            .catch((err) => {
                alert(err);
            });
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        SetDirty(property) {
            this.$v.ProdExpVendor[property].$touch();
        },
        ResetDirty(property) {
            this.$v.ProdExpVendor[property].$reset();
        },
        ValidateNumeric() {
            if (this.ProdExpVendor.Phone.length > 0) {
                this.validationtextbox = true;
            }
            else {
                this.validationtextbox = false;
            }
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Vendor Attestation",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Vendor Attestation",
                });
            }
        },
        async getPhoneNumber() {
            try {
                const emailAddress = await this.$store.dispatch("getEmail");
                const res = await this.$store.dispatch("product/GetReachUser", emailAddress);
                if (res.data) {
                    this.ProdExpVendor.Phone = res.data.Phone;
                    this.userPhone = this.ProdExpVendor.Phone ? this.ProdExpVendor.Phone.length : null;
                    this.reachUserData = res.data;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
    },
}
</script>
